
import { Component, Vue } from 'vue-property-decorator'
import LndTelInput from '@/components/lnd/TelInput.vue'
import LndCountryInput from '@/components/lnd/CountryInput.vue'
import { ILandingOrder, IProductSentencesResponse } from '@/interfaces'
import { dispatchGetProductSentencesSet, dispatchSubmitLandingOrder, dispatchGetDeliveryCountries } from '@/store/main/actions'
import Spinner from '@/components/Spinner.vue'
import { del } from 'vue'

@Component({
  components: {
    Spinner,
    LndTelInput,
    LndCountryInput,
  }
})
export default class LndPopupCheckout extends Vue {
  public order: ILandingOrder = {
    products: [],
    prePhone: '',
    phone: '',
    country: '',
    city: '',
    delivery_address: '-',
    index: '',
    email: '',
    full_name: '',
    first_name: '',
    last_name: '',
    promocode: '',
    starlink_kit: false,
  }

  public deliveryCountries: any[] = []

  public loading: boolean = false

  public formError: boolean = false
  public emailExists: boolean = false
  public phoneExists: boolean = false
  public isPhoneValid: boolean = false

  public city: string = ''
  public country: any = null
  public deliveryAddress: string = ''
  public index: string = ''

  public latinReplaceRegex = /[^a-zA-Z\s-]/g;
  public latinRegex = /^[a-zA-Z\s-]*$/;

  public addressRegex = /^[a-zA-Z0-9\s#"'()+-:]*$/;
  public addressReplaceRegex = /[^a-zA-Z0-9\s#"'()+-:]/g;

  public sentencesResponse: IProductSentencesResponse = {
    products: [],
    promocode: null,
  }

  get validateForm() {
    if (this.deliveryNotAvailable) {
      this.formError = !this.order.first_name
      || !this.order.last_name
      || !this.order.phone
      || !this.order.email
      || !this.order.country
      || !this.order.promocode
      || !this.sentencesResponse.promocode
    } else {
      this.formError = !this.order.first_name
        || !this.order.last_name
        || !this.order.phone
        || !this.order.email
        || !this.order.country
        || !this.order.city
        || !this.order.delivery_address
        || !this.order.index
        || !this.order.promocode
        || !this.sentencesResponse.promocode
    }

    return !this.formError
  }

  get deliveryOption() {
    return this.deliveryCountries.find(({ country }) => country === this.country?.iso)
  }

  public get deliveryNotAvailable() {
    return !this.deliveryOption
  }

  public get totalPrice() {
    // if (this.country && !this.deliveryNotAvailable && this.deliveryOption.delivery_product) {
    //   return 145 + this.deliveryOption.delivery_product.price
    // } else {
      return 145
    // }
  }

  public async handleUpdatePromocode() {
    console.debug('%c handleUpdatePromocode(%s)', 'color:magenta;font-size:18px;', this.order.promocode)

    const response = await dispatchGetProductSentencesSet(
      this.$store, {
        // product_sentences_set: 'landing',
        product_sentences_set: 'dexnodeWithKey',
        promocode: this.order.promocode,
      },
    );
    if (response) {
      this.sentencesResponse = response;
    }
  }

  public updatePhoneData(data: any) {
    // console.debug('%c LndPopupCheckout.vue $emit.udpatePhoneData: %s', 'color:lime;', JSON.stringify(data, null, 4))
    this.isPhoneValid = data.isValid
    this.order.phone = data.isValid ? data.fullNumber : ''
  }

  public async handleSubmit() {
    if (this.loading) return

    console.log('%c handleSubmit(%s)', 'color:gold;', JSON.stringify(this.order, null, 4));

    // Если поля город и страна не заполнены, отправить на сервер '_'
    this.order.city = this.city ? this.city : '_'
    this.order.country = this.country ? this.country?.iso : '_'
    this.order.delivery_address = this.deliveryAddress ? this.deliveryAddress : '_'
    this.order.index = this.index ? this.index : '_'

    if (this.validateForm) {
      this.emailExists = false;
      this.phoneExists = false;

      this.loading = true
      // this.order.products.push(this.mainProduct ? this.mainProduct.id : 1);

      // Отправить айдишники всех продуктов
      this.order.products = this.sentencesResponse.products.map((product) => product.id)

      // if (this.order.starlink_kit) {
      //   this.order.products.push(this.starlinkProduct ? this.starlinkProduct.id : 2);
      // }
      const response = await dispatchSubmitLandingOrder(this.$store, this.order);
      if (response.status === 200 || response.status === 201) {
        // this.$router.push(`/dashboard?open_order=${response.data.order.id}`);
        // TODO Временно убрал. Разобраться, как сбрасывать все стили, при изменении роута.
        window.location.pathname = `/dashboard?open_order=${response.data.order.id}`
      } else if (response.status === 400) {
        if (response.data.email) {
          this.$toast.error(this.$t('Email is already exists').toString());
          this.emailExists = true;
        } else if (response.data.phone) {
          this.$toast.error(this.$t('Phone is already exists').toString());
          this.phoneExists = true;
        } else {
          this.$toast.error(this.$t('Error').toString());
        }
      } else {
        this.$toast.error(this.$t('Error').toString());
      }
      this.loading = false
    }
  }

  public checkFirtLastName() {
    const first_name = this.order.first_name || ''
    const last_name = this.order.last_name || ''

    if (!this.latinRegex.test(first_name)) {
      this.order.first_name = first_name.replace(this.latinReplaceRegex, '')
    }

    if (!this.latinRegex.test(last_name)) {
      this.order.last_name = last_name.replace(this.latinReplaceRegex, '')
    }
  }

  public checkDeliveryAddress() {
    const delivery_address = this.deliveryAddress || ''

    if (!this.addressRegex.test(delivery_address)) {
      this.deliveryAddress = delivery_address.replace(this.addressReplaceRegex, '')
    }
  }

  public checkCity() {
    const city = this.city || ''

    if (!this.latinRegex.test(city)) {
      this.city = city.replace(this.latinReplaceRegex, '')
    }
  }

  async created() {
    this.deliveryCountries = await dispatchGetDeliveryCountries(this.$store)
  }
}
